<template>
  <div class="box">
    <!-- 顶部图片 -->
    <div class="topBg">
      <div class="integral">
        我的积分:<span style="font-weight: 700">{{ integralNum }}</span
        >分
      </div>
    </div>
    <div style="margin-top: 184px">
      <!-- tabs切换 -->
      <van-tabs :active="type" sticky @change="CateIdClick">
        <van-tab
          v-for="item in tabList"
          :key="item.SortId"
          :title="item.Name"
          :name="item.SortId"
          color="#ff4500"
          background="#ff4500"
        >
        </van-tab>
      </van-tabs>
      <!-- 列表 -->
      <!-- 没有更多了 -->
      <van-empty v-if="!list.length" description="─── 暂无商品列表 ───" />
      <div class="lists" v-else>
        <div class="list" v-for="item in list" :key="item.id">
          <div
            @click="gotoUrl('/integral/detail/' + item.GoodsId + '-' + order)"
          >
            <div class="listImg">
              <img
                :src="item.Img"
                style="width: 100%; height: 100%; border-radius: 8px 8px 0 0"
              />
            </div>
            <div class="listTitle">
              <span>{{ item.Name }}</span>
            </div>
          </div>

          <div class="listLabel">
            <span
              >{{ item.Amount }}
              <span style="font-weight: normal; font-size: 12px"
                >分/{{ item.MUnit }}</span
              >
            </span>
            <span>{{ item.CateName }}</span>
          </div>
          <van-stepper
            v-model="item.GoodsNum"
            min="0"
            button-size="18"
            disable-input
            @change="Goodschange1(item)"
            style="
              display: inline-block;
              float: right;
              margin-bottom: 9px;
              margin-right: 9px;
            "
          />
        </div>
      </div>
    </div>
    <div class="bottom">
      <span class="heji"
        >合计:&nbsp;
        <span style="font-size: 20px; color: #ff4b33; font-family: 'Price'">
          {{ totalGold }}
        </span>
        <span style="font-size: 14px; color: #ff4b33">分</span></span
      >
      <van-button type="primary" class="btn" @click="shoppingClick()"
        >去结算</van-button
      >
    </div>
    <!-- 购物车 -->
    <!-- <div class="shopping" @click="shoppingClick()" v-if="shoppongShow">
      <img
        src="../../assets/shop/shopping.png"
        alt=""
        style="width: 60%; margin: 12px 17%"
      />
    </div> -->
    <div>
      <van-popup
        @close="ClosePopup"
        v-model="shoppingShow"
        round
        position="bottom"
        :style="{ height: '60%' }"
      >
        <!-- 商品列表 -->
        <div v-if="shopList != 0" class="goodsLists">
          <div
            class="goodsList"
            v-for="item in shoppingList"
            :key="item.GoodsId"
          >
            <div style="width: 36%" v-if="item.GoodsNum != 0">
              <img
                @click="goodsDelete(item.OGoodsId)"
                src="../../assets/del.png"
                alt=""
                style="position: absolute; right: 0; width: 25px"
              />
              <img
                :src="item.GoodsImg"
                style="width: 83%; height: 100%; border-radius: 10px 0 0 10px"
              />
            </div>
            <div style="width: 63%" v-if="item.GoodsNum != 0">
              <div class="flex">
                <span style="color: #191919; font-weight: 700">{{
                  item.GoodsName
                }}</span>
                <!-- <span>{{ item.Price }}/{{ item.MUnit }}</span> -->
              </div>
              <div class="flex">
                <span style="font-family: Price; color: #999; font-size: 14px"
                  >{{ item.Price }}
                  <span style="font-family: none; font-size: 12px"
                    >/{{ item.MUnit }}</span
                  ></span
                >
              </div>
              <div style="display: flex; justify-content: space-between">
                <span style="color: #ee0c24">{{ item.Amount }}分</span>
                <span>
                  <van-stepper
                    v-model="item.GoodsNum"
                    min="0"
                    button-size="22"
                    disable-input
                    @change="Goodschange(item)"
                    style="display: inline-block; margin-right: 10px"
                  />
                </span>
              </div>

              <!-- <div class="flex">
                <button @click="goodsDelete(item.OGoodsId)" class="delete">
                  删除
                </button>
              </div> -->
            </div>
          </div>
          <!-- 结算按钮 -->
          <div class="button">
            <van-button @click="orderClick()">结 算</van-button>
          </div>
        </div>
        <van-empty v-else description="─── 暂无商品列表 ───" />
      </van-popup>
    </div>
    <!-- 收货地址 -->
    <van-popup
      v-model="addressShow"
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="address">
        <van-radio-group v-model="checked">
          <van-radio name="0">
            <div>
              <div class="radioBox">
                <span>自提</span>
              </div>
            </div>
          </van-radio>
          <van-radio
            :name="item.BindId"
            v-for="item in addressList"
            :key="item.BindId"
          >
            <div>
              <div class="radioBox">
                <span>收件人:{{ item.Name }}</span
                ><span>联系电话:{{ item.Mobile }}</span>
              </div>
              <div style="font-size: 14px; padding-top: 5px">
                地址:{{ item.Addr }}
              </div>
            </div>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="button">
        <van-button @click="PlaceOrder">支 付</van-button>
      </div>
      <div class="integralBox">
        <van-radio-group v-model="integralCk" direction="horizontal">
          <van-radio name="1">会员积分({{ CurIntegral }}分)</van-radio>
          <!-- <van-radio name="2">党员积分({{ PmCurIntegral }}分)</van-radio>
          <van-radio name="3">志愿者积分({{ VolCurIntegral }}分)</van-radio> -->
        </van-radio-group>
      </div>
    </van-popup>

    <van-action-sheet
      v-model="orderRenAddshow"
      title="添加收货人信息"
      @close="close()"
      :style="{ height: '70%' }"
    >
      <div class="content">
        <van-field
          v-model="oldmanForm.Name"
          class="shuru"
          name="oldmanForm.Name"
          label="收件人"
          placeholder="请输入收件人"
        />
        <van-field
          v-model="oldmanForm.Mobile"
          class="shuru"
          name="oldmanForm.Mobile"
          label="联系电话"
          placeholder="请输入联系电话"
        />
        <van-field
          v-model="oldmanForm.Addr"
          type="textarea"
          rows="1"
          autosize
          class="shuru"
          name="oldmanForm.Addr"
          label="地址"
          placeholder="请输入地址"
        />
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <div class="button">
            <van-button @click="submit()">保存</van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import {
  WxGetGoodsPage,
  WxGetGoodsSortList,
  WeMyMemberInfo,
} from "@/api/RealInfo";
import {
  WeGenerateOrder,
  WeGetMyOrderPage,
  WeGetOrderGoodsList,
  WePlaceOrder,
  WeDeleteOrderGoods,
  WeSaveOrderService,
  WeGetMyBindInfoPage,
} from "@/api/shop";
import { getOpenId, setOpenId } from "@/utils/auth";
import { WeSaveBindInfo } from "@/api/ylOrder";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      oldmanForm: {
        Name: "",
        Mobile: "",
        Addr: "",
        BindId: undefined,
      },
      orderRenAddshow: false,
      shopList: "",
      GoodsId: "", // 商品id
      shopNum: "", //商品数量
      type: "", //tabs选中
      tabList: [], //tabs类型
      list: [], //商品列表
      integralNum: 0,
      shoppingList: [], //购物车列表
      shoppingShow: false, //购物车弹窗
      order: "", //订单
      addressShow: false, //收货地址
      shoppongShow: false, //购物车按钮
      addressList: [], //收货地址
      checked: "0", //收货地址选择
      integralCk: "1", //积分选择
      CurIntegral: "", //会员当前积分
      PmCurIntegral: "", //党员当前积分
      VolCurIntegral: "", //志愿者当前积分（时间币）
      totalGold: 0, //总金额
    };
  },
  created() {},
  mounted() {
    // setOpenId("ojciF5FL_BlLHTSPhVIfIQz_KR48"); //测试openid
    this.getTabsList();

    this.getMemberByOpenID();
    this.getUserOrder();
  },
  methods: {
    // 选择商品
    orderClick() {
      this.addressShow = true;
      WeGetMyBindInfoPage({ openID: getOpenId() }).then((res) => {
        if (res.data.count == 0) {
          Dialog.confirm({
            title: "提醒",
            message: "您还没有添加收货地址,请先新增!",
          })
            .then(() => {
              this.orderRenAddshow = true;
              // if (window.__wxjs_environment === "miniprogram") {
              //   window.wx.miniProgram.navigateTo({
              //     url: "../my/user/oldManList/oldManAdd/index?name='收货地址'",
              //   });
              // } else {
              //   alert("请在小程序中打开");
              // }
            })
            .catch(() => {
              return false;
            });
        } else {
          this.addressList = res.data.data;
        }
      });
    },
    onShow() {
      this.orderClick();
    },
    // 数量改变
    Goodschange1(row) {
      console.log(row);
      WeSaveOrderService({
        //添加订单商品
        OpenID: getOpenId(),
        OrderId: this.order,
        GoodsId: row.GoodsId,
        GoodsNum: row.GoodsNum,
      }).then((res) => {
        console.log(res, "成功啦");
        this.getshoppingList(this.order);
      });
    },
    getGoodsInfo(item) {
      console.log(item);
      this.GoodsId = item.GoodsId;
    },
    // 步进器选择
    Goodschange(row) {
      console.log(row, "222");
      WeSaveOrderService({
        //添加订单商品
        OpenID: getOpenId(),
        OrderId: this.order,
        GoodsId: row.GoodsId,
        GoodsNum: row.GoodsNum,
      }).then((res) => {
        // Toast(row.GoodsName + ":" + row.GoodsNum);
        this.getshoppingList(this.order);
      });
    },
    ClosePopup() {
      console.log("关闭啦!!!");
      this.getList();
    },
    close: function () {
      // console.log(123123);
      this.oldManList = "";
      this.oldmanForm.OMIdCard = "";
      this.oldmanForm.OMMobile = "";
      this.oldmanForm.OMAddr = "";
    },
    submit() {
      console.log(this.oldmanForm);
      if (this.oldmanForm.Name == "") {
        Toast.fail("请输入收件人");
      } else if (this.oldmanForm.Mobile == "") {
        Toast.fail("请输入联系电话");
      } else if (this.oldmanForm.Addr == "") {
        Toast.fail("请输入地址");
      } else {
        WeSaveBindInfo({
          OpenID: getOpenId(),
          Name: this.oldmanForm.Name,
          Mobile: this.oldmanForm.Mobile,
          Addr: this.oldmanForm.Addr,
          BindId: this.oldmanForm.BindId,
        }).then((res) => {
          if (res.data.code == "0") {
            Toast.success("添加成功");
            this.orderRenAddshow = false;
            // this.orderRenshow = true;
            this.oldmanForm.OMIdCard = "";
            this.oldmanForm.OMMobile = "";
            this.oldmanForm.OMAddr = "";
            this.oldmanForm.BindId = undefined;
            this.orderClick();
          } else {
            Toast.fail(res.data.msg);
          }
        });
      }
    },
    // 获取订单情况
    getUserOrder() {
      WeGetMyOrderPage({
        openID: getOpenId(),
        status: 1,
      }).then((res) => {
        if (res.data.code != 0) {
          Toast.fail(res.data.msg);
          this.shoppongShow = false;
        } else {
          if (res.data.count == 0) {
            // 没有订单 生成订单
            WeGenerateOrder({ OpenID: getOpenId() }).then((res) => {
              this.getshoppingList(res.data.data);
              this.order = res.data.data;
              this.shoppongShow = true;
            });
          } else {
            // 有订单使用现有订单
            this.getshoppingList(res.data.data[0].OrderId);
            this.order = res.data.data[0].OrderId;
            this.shoppongShow = true;
          }
        }
      });
    },
    // 获取购物车列表
    getshoppingList(row) {
      this.totalGold = 0;
      WeGetOrderGoodsList({ openID: getOpenId(), orderId: row }).then((res) => {
        this.shoppingList = res.data.data;
        for (let i = 0; i < this.shoppingList.length; i++) {
          // this.shopNum = this.shoppingList[i].GoodsNum;
          // console.log(this.shopNum);
          this.totalGold += this.shoppingList[i].Amount;
        }
        var arr = [];
        this.shoppingList.forEach((i) => {
          arr.push(i.GoodsNum);
        });
        var sum = arr.reduce(function (prev, cur) {
          return prev + cur;
        });
        this.shopList = sum;
        console.log(sum, "sum");
      });
    },
    // 购物车点击
    shoppingClick() {
      this.shoppingShow = true;
      this.getshoppingList(this.order);
    },
    // 切换分类
    CateIdClick(row) {
      console.log(row, "row");
      this.type = row;
      this.getList();
    },
    // 获取不同分类列表
    getTabsList() {
      WxGetGoodsSortList().then((res) => {
        this.tabList = res.data.data;
        console.log(this.tabList[0].SortId, "this.tabList");
        this.type = this.tabList[0].SortId;
        this.getList();
      });
    },
    // 获取列表
    getList() {
      var sortId = this.type;
      WxGetGoodsPage({ sortId: sortId, openID: getOpenId() }).then((res) => {
        this.list = res.data.data;
        console.log("this", this.list);
      });
    },
    // 获取会员信息
    getMemberByOpenID() {
      WeMyMemberInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == 0) {
          var data = res.data.data;
          console.log(data, "积分");
          this.integralNum = data.CurIntegral;
          this.CurIntegral = data.CurIntegral;
          this.PmCurIntegral = data.PmCurIntegral;
          this.VolCurIntegral = data.VolCurIntegral;
        } else {
          Toast.fail("您还不是会员,请先注册会员!");
        }
      });
    },
    // 下单结算
    PlaceOrder() {
      if (this.checked == 0) {
        var DeliveryMode = 2;
        this.checked = undefined;
      } else {
        var DeliveryMode = 1;
      }
      if (this.totalGold > this.integralNum) {
        Toast.fail("积分不足,无法兑换商品!");
        return false;
      }
      WePlaceOrder({
        OpenID: getOpenId(),
        ScoreType: this.integralCk,
        BindId: this.checked,
        DeliveryMode: DeliveryMode,
        OrderId: this.order,
        GoodsScore: this.totalGold,
      }).then((res) => {
        if (res.data.code == 0) {
          Toast.success("提交成功!");
          this.addressShow = false;
          this.shoppingShow = false;
          this.getMemberByOpenID();
          this.getUserOrder();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 删除单独商品
    goodsDelete(row) {
      WeDeleteOrderGoods({
        OpenID: getOpenId(),
        OrderId: this.order,
        OGoodsId: row,
      }).then((res) => {
        if (res.data.code == 0) {
          this.getshoppingList(this.order);
          this.shoppingShow = false;
          Toast.success("删除成功!");
        }
      });
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style>
@font-face {
}
</style>
<style scoped>
@font-face {
  font-family: "Price";
  src: url(../../assets//font//OPPOSANS-B.TTF);
}
@font-face {
  font-family: "PriceH";
  src: url(../../assets//font//OPPOSANS-H.TTF);
}
.box {
  background: #f7f8fa;
  /* height: 100%; */
  min-height: 100vh;
}

/* 背景 */
.topBg {
  position: absolute;
  height: 215px;
  width: 100%;
  background: url("../../assets/jfsc.png") no-repeat center;
  background-size: 100%;
}

.topBg .integral {
  position: absolute;
  right: 0px;
  top: 10px;
  background: #ffffffed;
  padding: 10px 22px;
  font-size: 12px;
  color: #fb7003;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

/* 列表 */
.lists {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.lists .list {
  width: 45%;
  margin-left: 3.5%;
  background: #fff;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lists .listImg {
  width: 100%;
  height: 200px;
  text-align: center;
  /* margin: 13px 5%; */
}

.listTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  padding: 10px 8px 8px;
  font-family: PingFang SC;
  font-weight: bold;
}

.listTitle span:nth-child(1) {
  color: #191919;
  font-weight: 700;
}

.listLabel span:nth-child(1) {
  color: #ff4b33;
  font-family: Price;
}

.listLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 0px 8px 8px;
}
/* 购物车 */
.shopping {
  position: fixed;
  right: 15px;
  bottom: 25px;
  width: 65px;
  height: 65px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #f5eeee;
}
.goodsLists {
  width: 95%;
  margin: 10px auto 10px;
  background: #fff;
  height: 57vh;
  border-radius: 10px;
  overflow-y: scroll;
}
.goodsList {
  position: relative;
  display: flex;
  justify-items: center;
  /* align-items: center; */
  background: #f7f8fa;
  border-radius: 10px;
  margin-bottom: 7px;
}
.button {
  width: 100%;
  text-align: center;
}
.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.button button {
  position: fixed;
  bottom: 15px;
  width: 80%;
  background: linear-gradient(to right, #f28c76, #fc4839);
  color: #fff;
  line-height: 48px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  left: 10%;
}
.goodsList .delete {
  background: #ee0c24;
  border: none;
  color: #fff;
  font-size: 14px;
  border-radius: 7px;
  padding: 5px 13px;
}
.address .van-radio {
  background: #f5f5f5;
  width: 80%;
  margin: 10px auto;
  padding: 6px 15px;
  border-radius: 6px;
}
.address .radioBox {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  font-size: 14px;
}
.integralBox {
  position: absolute;
  bottom: 81px;
  font-size: 12px;
  margin-left: 10%;
}
.bottom {
  width: 100%;
  height: 54px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  padding: 7px 8px 4px 20px;
}

.heji {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #232323;
  line-height: 40px;
}

.btn {
  width: 30%;
  height: 38px;
  line-height: 38px;
  background: #fc4839;
  border-radius: 19px;
  border: 1px solid #fc4839;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  right: 18px;
  /* float: right;
  right: 3%; */
}

/deep/ .van-tab {
  font-size: 15px;
  font-weight: 400;
  color: #999999;
  /* color: #000000; */
  /* font-family: PingFangSC; */
}

/deep/ .van-tab--active {
  font-weight: 600;
  color: #323233;
}

/deep/ .van-tabs__wrap {
  border-radius: 10px 10px 0px 0px;
}

/deep/ .van-tabs__nav {
  background-color: #f7f8fa;
}

/deep/ .van-tabs__line {
  background: #ff4b33;
  border-radius: 4px;
}
</style>